import { Button, Col, Image, Row } from 'antd';
import { logoBUBU } from 'assets/images/_index';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function CustomHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const headerButtons = [
    {
      title: 'Proje Bilgileri',
      redirectUrl: '/proje-bilgileri',
    },
  ];
  const handleButtonClick = (redirectUrl: string) => {
    navigate(redirectUrl);
  };

  return (
    <Row className='header' justify={'center'}>
      <Col span={22}>
        <Row justify={'space-between'}>
          <Col>
            <Image
              onClick={() => handleButtonClick('/')}
              preview={false}
              src={logoBUBU}
              alt='logo'
              className='logo cursor-pointer'
            />
          </Col>
          <Col>
            <Row className='header-buttons'>
              {headerButtons.map((button) => (
                <Col key={button.title}>
                  <Button
                    onClick={() => handleButtonClick(button.redirectUrl)}
                    className={`header-buttons-button text text-center fs-14-px fw-500${
                      location.pathname === button.redirectUrl ? ' active' : ''
                    }`}
                  >
                    {button.title}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

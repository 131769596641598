import { Row, Col } from 'antd';
import MobileProductNavigation from 'components/CustomComponents/mobileProductNavigation/MobileProductNavigation';
import ContentSideBar from 'components/CustomComponents/Navigation/ContentSideBar';
import ProductViewer from 'components/CustomComponents/productViewer/ProductViewer';
import Spinner from 'components/UIComponents/Spinner/Spinner';
import { baseRequestBody } from 'helpers/constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import { IProductDetails, ISubProductDetails } from 'store/interfaces';
import { setPageHeaderProps } from 'store/PageHeader/PageHeader';
import { productDetails } from 'store/products/ProductDetails';

export default function Projects() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isMobilDevice = useSelector((state: RootState) => state.globalStates.isMobilDevice);
  const [selectedProduct, setSelectedProduct] = useState<IProductDetails>();
  const [selectedSubProduct, setSelectedSubProduct] = useState<ISubProductDetails>();

  useEffect(() => {
    dispatch(
      productDetails({
        body: { ...baseRequestBody, redirectUrl: '/proje-bilgileri' },
        onSuccess: (data: IProductDetails) => {
          setSelectedSubProduct(data.productDetails[0]);
          setSelectedProduct(data);
        },
        onError: () => {
          navigate('/not-found');
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (selectedProduct && selectedSubProduct) {
      dispatch(
        setPageHeaderProps({
          imageUrl: selectedSubProduct.mainImageUrl,
          text: selectedProduct.name,
          contentNavigation: [
            {
              name: selectedProduct.name,
              redirectUrl: selectedProduct.redirectUrl,
            },
            {
              name: selectedSubProduct.name,
              redirectUrl: selectedProduct.redirectUrl,
            },
          ],
        }),
      );
    }
  }, [selectedProduct, selectedSubProduct]);

  const handleSubProductSelection = (subProduct: ISubProductDetails) => {
    setSelectedSubProduct(subProduct);
  };
  const handleSubProductSelectionMobil = (subProduct: ISubProductDetails) => {
    if (subProduct.name == selectedSubProduct?.name) {
      setSelectedSubProduct({ ...subProduct, name: subProduct.name + ' ' });
      return;
    }
    handleSubProductSelection(subProduct);
  };

  return selectedSubProduct && selectedProduct ? (
    <Row>
      <Col span={24}>
        {isMobilDevice ? (
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <MobileProductNavigation
                selectedProduct={selectedProduct}
                selectedSubProduct={selectedSubProduct}
                setSelectedSubProduct={handleSubProductSelectionMobil}
              />
            </Col>
            <Col span={24}>
              <ProductViewer selectedSubProduct={selectedSubProduct} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <Row justify={'space-between'}>
                <Col span={5}>
                  <ContentSideBar
                    items={selectedProduct.productDetails}
                    handleItemChange={handleSubProductSelection}
                    selectedItem={selectedSubProduct}
                    selectedSubItem={undefined}
                  />
                </Col>
                <Col span={18}>
                  <ProductViewer selectedSubProduct={selectedSubProduct} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  ) : (
    <Spinner />
  );
}
